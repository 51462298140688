<template>
  <section id="marketing-dashboard">
    <b-overlay :show="showMarketingDashboard" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-row>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Cost
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-cost" />
              </h4>
              <b-popover target="popover-cost" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="costChart" :options="costChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Cost per conversion
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-cost-per-conversion" />
              </h4>
              <b-popover target="popover-cost-per-conversion" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="costPerConversionChart" :options="costPerConversionChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Total conversion value
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-total-conversion-value" />
              </h4>
              <b-popover target="popover-total-conversion-value" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="totalConversionValueChart" :options="totalConversionValueChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6" md="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Return on ad spend (ROAS)
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-roas" />
              </h4>
              <b-popover target="popover-roas" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="roasChart" :options="roasChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCardBody,
  BCardHeader,
  BCardText,
  BPopover,
  BCard,
  BOverlay,
} from 'bootstrap-vue';
import axios from 'axios';
import ECharts from 'vue-echarts';

import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BPopover,
    BOverlay,
    ECharts,
  },
  data() {
    return {
      showMarketingDashboard: true,
      costChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      costPerConversionChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      totalConversionValueChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      roasChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
    }
  },
  async created() {
    try {
      await this.getData();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getData() {
      this.showMarketingDashboard = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/marketing/`, {});
        const results = response.data.results;

        const monthYearLabels = results.map(item => item.month_year);

        this.costChart.xAxis.data = monthYearLabels;
        this.costChart.series = {
          name: "Cost",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          markLine: {
            symbol: 'none',
            data: [{ type: 'average', name: 'Avg' }]
          },
          data: results.map(item => item.cost),
        };

        this.costPerConversionChart.xAxis.data = monthYearLabels;
        this.costPerConversionChart.series = {
          name: "Cost per conversion",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          markLine: {
            symbol: 'none',
            data: [{ type: 'average', name: 'Avg' }]
          },
          data: results.map(item => item.cost_per_conversion),
        };

        this.totalConversionValueChart.xAxis.data = monthYearLabels;
        this.totalConversionValueChart.series = {
          name: "Total conversion value",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          markLine: {
            symbol: 'none',
            data: [{ type: 'average', name: 'Avg' }]
          },
          data: results.map(item => item.total_conversion_value),
        };

        this.roasChart.xAxis.data = monthYearLabels;
        this.roasChart.series = {
          name: "Return on ad spend (ROAS)",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          markLine: {
            symbol: 'none',
            data: [{ type: 'average', name: 'Avg' }]
          },
          data: results.map(item => item.roas),
        };

      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showMarketingDashboard = false;
      }
    },
  },
}
</script>
